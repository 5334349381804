import { generateSEOCategoryLink } from "../linkGenerators";
import { fromJS } from "immutable";
import { postToSite, fetchGoogleoAuthUrl } from "../apiClient";
import { sendClevertapEvent, sendClevertapOnUserLoginEvent } from "./clevertap";
import { DEFAULT_COUNTRY_CODE } from "../constants/common";
import queryString from "query-string";
import { isEmpty } from "lodash";
import { categoryCarouselsToPin } from "../constants/common";
import { List } from "immutable";
import { ShowOtpOnTypeIssued } from "../../helpers/constants";
import moment from "moment";
import { isH5Build } from ".";
import { openInBrowserForApp } from "../appBridge";
/**
 * This is our generic helper to log
 * errors in the app
 * @todo push error to GA / errorception here
 */
export const errorLoading = err => {
    console.error("Dynamic page loading failed", err);
};
/**
 * Returns a function that loads a route
 * asynchronously using React Router v3's
 * getComponent callback
 */
export const loadRoute = LoadAsyncRoute => (_, cb) => LoadAsyncRoute()
    .then(module => cb(null, module.default))
    .catch(errorLoading);
// https://regex101.com/r/ExDUy2/1
export const checkIfSummerCity = selectedCity => /(mumbai|bangalore|delhi)$/.test(selectedCity);
/**
 * Gets unique set from an array
 * @deprecated
 */
export const unique = items => {
    const seen = {};
    const result = [];
    for (const item of items) {
        if (!(item in seen)) {
            seen[item] = true;
            result.push(item);
        }
    }
    return result;
};
export const isExternalHref = href => {
    const domain = url => {
        return url
            .replace("http://", "")
            .replace("https://", "")
            .split("/")[0];
    };
    if (href.indexOf("http") === 0 || href.indexOf("https") === 0) {
        return domain(href) !== "insider.in";
    }
    else {
        return false;
    }
};
// handling this https://insider.in/{event_slug}/event
// Event slug can't have any slash
const EDPHrefRegEx = "^https://insider.in/[^/]*/event$";
export const isEDPHref = href => href && href.match(EDPHrefRegEx);
export const getSlugFromEDPHref = href => href && href.slice(19).slice(0, -6);
export const slugify = s => {
    return encodeURIComponent(s.replace(/\s/g, "-").toLowerCase());
};
export const separator = (array, separator) => {
    for (let i = 1; i < array.length; i += 2) {
        array.splice(i, 0, separator);
    }
    return array;
};
export const generateLinkFromCategory = (selectedCity, category, timeFilter, sort, priceFilter, type) => {
    const href = generateSEOCategoryLink(selectedCity, category.get("name"), timeFilter, sort, priceFilter, type);
    const count = category.get("eventCount");
    const name = category.get("name");
    return {
        href,
        count,
        name
    };
};
// TODO: needs to be a selector!
export const getFullName = (firstName, lastName) => {
    if (!firstName && !lastName) {
        return "";
    }
    return `${firstName} ${lastName}`;
};
export const facebookLoginCallback = (response, setLoginError, loginCallback, context) => {
    // If there was an error logging in via Facebook.
    if (!response.id && (!response.status || response.status === "unknown")) {
        setLoginError("Could not register using Facebook, check your Facebook credentials");
        if (window.ga) {
            ga("send", {
                hitType: "event",
                eventCategory: "FbLoginCancel",
                eventAction: context,
                eventLabel: window.location.pathname
            });
        }
        sendClevertapEvent("FollowFlow", {
            action: "FBLoginCancel",
            location: context,
            url: window.location.pathname
        });
        return null;
    }
    // Otherwise, use FB data to register the user.
    const params = {
        userObj: {
            email: response.email,
            OAuthId: response.id,
            FacebookOAuthToken: response.accessToken
        }
    };
    const body = JSON.stringify(params);
    return postToSite(`/users/auth/client/register`, body)
        .then(response => {
        if (response.status < 400) {
            return response;
        }
        throw new Error("Bad status code");
    })
        .then(response => response.json())
        .then(response => {
        const userData = fromJS(response);
        if (window.ga) {
            ga("send", {
                hitType: "event",
                eventCategory: "FbLogin",
                eventAction: context,
                eventLabel: window.location.pathname
            });
        }
        sendClevertapOnUserLoginEvent(userData, {
            action: "signup",
            method: "facebook"
        });
        loginCallback(userData);
    })
        .catch(function (err) {
        setLoginError("Could not register using Facebook, server error");
    });
};
export const googleLoginSuccessCallback = (response, setLoginError, loginCallback, context) => {
    const params = {
        userObj: {
            OAuthId: response.googleId,
            email: response.profileObj ? response.profileObj.email : response.email,
            GoogleOAuthToken: response.accessToken
        }
    };
    const body = JSON.stringify(params);
    return postToSite(`/users/auth/client/register`, body)
        .then(response => {
        if (response.status < 400) {
            return response;
        }
        throw new Error("Bad status code");
    })
        .then(response => response.json())
        .then(response => {
        const userData = fromJS(response);
        if (window.ga) {
            ga("send", {
                hitType: "event",
                eventCategory: "GoogleLogin",
                eventAction: context,
                eventLabel: window.location.pathname
            });
        }
        sendClevertapOnUserLoginEvent(userData, {
            action: "signup",
            method: "google"
        });
        loginCallback(userData);
    })
        .catch(function (err) {
        console.log(err);
        setLoginError("Could not register using Google, server error");
    });
};
export const googleLoginErrorCallback = (response, setLoginError, context) => {
    if (response.error) {
        setLoginError("Could not register using Google, please check your Google credentials");
        if (window.ga) {
            ga("send", {
                hitType: "event",
                eventCategory: "GoogleLoginCancel",
                eventAction: context,
                eventLabel: window.location.pathname
            });
        }
        sendClevertapEvent("FollowFlow", {
            action: "GoogleLoginCancel",
            location: context,
            url: window.location.pathname
        });
        return null;
    }
};
export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];
export const unixEpochToDate = utcSeconds => {
    const d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds);
    const month = months[d.getMonth()];
    return `${d.getDate()} ${month} ${d.getFullYear()}`;
};
export const validatePhoneNo = async (phoneNo) => {
    try {
        const response = await postToSite(`/validate/phone_no`, JSON.stringify({ phone_no: phoneNo }));
        return response.json();
    }
    catch (error) {
        throw new Error("Could not verify phone number");
    }
};
export const leftPad = number => (number <= 9 ? `0${number}` : `${number}`);
export const unixEpochToDateYYYYMMDD = utcSeconds => {
    const d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds);
    return `${d.getFullYear()}-${leftPad(d.getMonth() + 1)}-${leftPad(d.getDate())}`;
};
export function promiseTimeout(promise, timeoutMs) {
    return Promise.race([
        promise,
        new Promise((resolve, reject) => {
            setTimeout(() => {
                reject(`Promise timed out in ${timeoutMs} ms`);
            }, timeoutMs);
        })
    ]);
}
export const isValidEmail = email => {
    const emailRegex = /^(([a-z0-9]+)([-_.][a-z0-9]+)*([+][a-z0-9]+)?)@([a-z]|[a-z0-9]?[a-z0-9-\.]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/;
    return emailRegex.test(email);
};
export const isValidPhoneNumberWithCountryCode = phoneNumber => {
    const phoneNumberRegex = /^\+\d{8,14}$/;
    return phoneNumberRegex.test(phoneNumber);
};
export function isPhoneNumberValid(phone) {
    let result = {
        isValid: true,
        errorMessage: ""
    };
    if (!phone) {
        result.errorMessage = "Please enter a mobile number.";
        result.isValid = false;
        return result;
    }
    else if (phone.length < 7 || phone.length > 15) {
        result.errorMessage = "Please enter a valid mobile number";
        result.isValid = false;
        return result;
    }
    else if (!phone.includes("+")) {
        result.errorMessage = "Please enter a valid phone number including country code (eg: +91)";
        result.isValid = false;
        return result;
    }
    else if (!/^[\d\+]+$/.test(phone)) {
        result.errorMessage = "Phone numbers can only contain digits and the '+' symbol";
        result.isValid = false;
        return result;
    }
    return result;
}
export const truncateString = (numberOfCharacters, str) => {
    if (str.length > numberOfCharacters) {
        return str.slice(0, numberOfCharacters) + "...";
    }
    return str;
};
export const addDefaultCountryCodeToPhone = phone => {
    if (!phone || !/^[\d\+]+$/.test(phone)) {
        return;
    }
    if (phone.includes("+") || phone.length !== 10) {
        return phone;
    }
    return DEFAULT_COUNTRY_CODE + phone;
};
export const hexToRgba = (hex, defaultOpacity) => {
    if (hex.length === 9) {
        const decimalValue = parseInt(hex.substr(3, 6), 16);
        const r = (decimalValue >> 16) & 255;
        const g = (decimalValue >> 8) & 255;
        const b = decimalValue & 255;
        const alpha = parseInt(hex.substr(1, 2), 16) / 255;
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    else if (hex.length === 7) {
        const decimalValue = parseInt(hex.slice(1), 16);
        const r = (decimalValue >> 16) & 255;
        const g = (decimalValue >> 8) & 255;
        const b = decimalValue & 255;
        if (defaultOpacity) {
            return `rgba(${r}, ${g}, ${b}, ${defaultOpacity})`;
        }
        return `rgb(${r}, ${g}, ${b})`;
    }
    console.warn(`received invalid color "${hex}" from server, skipping hex to rgba conversion`);
    return hex;
};
export const redirectToGoogleLogin = async (redirectUrl) => {
    try {
        const response = await fetchGoogleoAuthUrl(redirectUrl);
        window.location.href = response.url;
    }
    catch (e) {
        window && window._errs && window._errs.push(`Error while redirecting to google login: ${e}`);
    }
};
export const generateDigitalEntryPDFLink = uuid => {
    return `https://tkt.insider.in/pdf/${uuid}.pdf`;
};
export const initSentry = Sentry => {
    if (typeof window !== "undefined") {
        Sentry.init({
            dsn: "https://9b903768a78b48a098abed52d66a8022@sentry.insider.in/3",
            enabled: window.env === "live"
        });
    }
};
export const isFromApp = () => {
    const query = queryString.parse(window.location.search);
    const platform = query["platform"];
    if (platform) {
        return platform === "android" || platform === "ios";
    }
    return false;
};
/**
 * Filters an array of items based on city inclusion and exclusion criteria.
 *
 * @param {Array} items - The array of items to be filtered.
 * @param {string} selectedCity - current city
 * @returns {Array} The filtered array of items.
 *
 * The function considers the following rules:
 * - If both includeCities and excludeCities keys are missing, the item will be hidden.
 * - If both keys are present, includeCities takes precedence over excludeCities.
 * - Adding "global" to the includeCities array will make the item visible in all cities.
 */
export const filterItemsByCity = (items, selectedCity) => {
    return items.filter(item => {
        const includesSelectedCity = !isEmpty(item.includeCities) &&
            (item.includeCities.includes(selectedCity) || item.includeCities.includes("global"));
        const selectedCityIsNotExcluded = isEmpty(item.includeCities) &&
            !isEmpty(item.excludeCities) &&
            !item.excludeCities.includes(selectedCity) &&
            !item.excludeCities.includes("global");
        return includesSelectedCity || selectedCityIsNotExcluded;
    });
};
/**
 * Filters an array of items based on city inclusion and exclusion criteria.
 *
 * @param {boolean} isEmbed - Boolean value if it finds embed in query params
 * @param {string} merchantId -  value checking if there's any value present agianst merchantId quey params
 *  @param {Array} cookieKeys -  gives all the cookies in array
 * @returns {boolean}  return whether to show city selector overlay
 *
 * The function considers the following rules:
 * -IF there is queuefare cookie is present, i.e user coming from queue, hide overlay
 * - if url has embed==true and have merchantId, it is coming from embed, hide overlay
 * - check if user on / lands on buy page / checkout page flow, hide overlay
 */
export const checkToShowCitySelectorOverlay = (isEmbed, merchantId, cookieKeys) => {
    const url = window && window.location && window.location.pathname;
    const queueNamePrefix = "QueueFair-Pass-";
    const isEmbedPage = isEmbed && merchantId;
    const isQueuedTicketEvent = cookieKeys && cookieKeys.find(cookie => cookie.startsWith(queueNamePrefix));
    console.log('===> url', url);
    const isBuyorCheckoutPage = url &&
        (url.includes("/buy-page") ||
            url.includes("/buy") ||
            url.includes("/checkout") ||
            url.includes("/addons") ||
            url.includes("/event"))
        ? true
        : false;
    const isSchweppesLandingPage = url && url.includes('/schweppes-born-social-club');
    return isBuyorCheckoutPage || isEmbedPage || isQueuedTicketEvent || isSchweppesLandingPage;
};
/**
 * Sort events based on carousal position of tag.
 *
 * @param events
 * @param {string} tagToBeSorted
 *
 * The function sort on the following rules:
 * - is_carousel === true
 * - If two event has same carousal position sorting will be based on min_show_start_utc_timestamp
 * - Event having lowest carousal position will be first like(1,2,3)
 */
export const sortEventByTagCarousalPosition = tagToBeSorted => (a, b) => {
    const getCarousalValue = tags => {
        const tagId = tags.find(tag => tag.get("is_carousel") === true && tag.getIn(["tag_id", "slug"]) === tagToBeSorted);
        return tagId ? tagId.get("carousel_position") : 1000000;
    };
    const carousalAPos = getCarousalValue(a.get("tags"));
    const carousalBPos = getCarousalValue(b.get("tags"));
    if (carousalAPos === carousalBPos) {
        return a.get("min_show_start_utc_timestamp") - b.get("min_show_start_utc_timestamp");
    }
    return carousalAPos - carousalBPos;
};
export const generateOTPverificationPIN = (transactionId, shortcode) => {
    const hasheEencodedString = btoa(transactionId + shortcode);
    const finalVerificationPin = hasheEencodedString
        .replace(/[^A-Za-z0-9]/g, "")
        .toUpperCase()
        .slice(-4);
    return finalVerificationPin;
};
export const checkIfPickupTicket = (delivery_type, delivery_details, itemTypesInSameShortcode, ticketPurchaseType, thisEventId = "") => {
    const showOTPforIssueType = thisEventId
        ? ShowOtpOnTypeIssued.includes(thisEventId) && ticketPurchaseType == "issued"
        : false;
    return (itemTypesInSameShortcode.includes("physical") &&
        (delivery_type === "pickup" || delivery_type === "delivery_or_pickup") &&
        !delivery_details &&
        (ticketPurchaseType === "online" || showOTPforIssueType));
};
export const getOSFromUserAgent = () => {
    const userAgent = typeof window !== "undefined" && typeof window.navigator !== "undefined" ? window.navigator.userAgent : "";
    if (/iPad|iPhone|iPod/.test(userAgent) ||
        (window && window.navigator && window.navigator.platform === "MacIntel" && window.navigator.maxTouchPoints > 1)) {
        return "ios";
    }
    else if (/Android/.test(userAgent)) {
        return "android";
    }
    else {
        return "web";
    }
};
export const getCards = (cards, allCards) => {
    // if the number of cards in any category is less than 3 then don't show it
    const cardsList = cards.filter(card => card.get("events").size >= 3);
    // this is a hack done to pin category carousel
    const reorderCards = [];
    categoryCarouselsToPin.forEach(category => {
        const card = cardsList.find(card => card.get("name") === category);
        if (card !== undefined)
            reorderCards.push(card);
    });
    const filteredCards = cardsList.filter(card => !categoryCarouselsToPin.includes(card.get("name")));
    // online events are shown if physical events are filtered out
    return List(reorderCards).concat(filteredCards, allCards);
};
export const convertIntoMoment = timestamp => {
    return moment.utc(timestamp * 1000).utcOffset("+05:30");
};
export const getDurationOfEvent = (availableShows) => {
    const shows = [...availableShows];
    if (shows.length > 0) {
        const firstShow = shows[0] || {};
        const firstShowStartTime = firstShow.start_utc_timestamp;
        const firstShowEndTime = firstShow.end_utc_timestamp;
        if (firstShowStartTime && firstShowEndTime) {
            const startMomentTime = convertIntoMoment(firstShowStartTime);
            const endtMomentTime = convertIntoMoment(firstShowEndTime);
            const diffInMilliseconds = endtMomentTime.diff(startMomentTime, 'milliseconds');
            let hours = diffInMilliseconds / 1000 / 60 / 60;
            // Convert days and hours to a string with a maximum of 2 decimal places
            hours = hours.toFixed(2);
            let durationString = "";
            if (hours < 12) {
                const roundedHourDiff = (Math.round(hours * 2) / 2);
                durationString = `${roundedHourDiff} hr`;
            }
            else if (hours <= 24) {
                durationString = '1 day';
            }
            else {
                const dayDiff = Math.ceil(hours / 24);
                if (dayDiff < 4)
                    durationString = `${dayDiff} days`;
            }
            return {
                duration: durationString,
                startTime: startMomentTime,
                endTime: endtMomentTime
            };
        }
    }
    return {};
};
export const formatTime = (time) => {
    if (!time)
        return '';
    const timeFormatter = time.minutes() === 0 ? "h A" : "h:mm A";
    return time.format(timeFormatter);
};
export const generateDateString = (startTimestamp, endTimestamp) => {
    let startDateTime = convertIntoMoment(startTimestamp);
    const timeFormatter = startDateTime.minutes() === 0 ? "h A" : "h:mm A";
    let endDateTime = convertIntoMoment(endTimestamp);
    const yearDiff = endDateTime.diff(startDateTime, "year");
    const dayDiff = endDateTime.diff(startDateTime, "day");
    const dateFormatter = yearDiff > 0 ? "D MMM YYYY" : "D MMM";
    if (dayDiff > 0) {
        return (startDateTime.format(dateFormatter) +
            " - " +
            endDateTime.format(dateFormatter) +
            ", " +
            startDateTime.format(timeFormatter));
    }
    else {
        return startDateTime.format(dateFormatter) + ", " + startDateTime.format(timeFormatter);
    }
};
export const generateEventDateString = (venue, isOnline) => {
    let dateString = (venue.date_string ? venue.date_string : "").replace(/\|/g, ",");
    let subString = '';
    const shows = venue.shows || [];
    if (shows && shows.length > 0) {
        const firstShow = shows[0];
        let smallestShowTimestamp = firstShow.start_utc_timestamp ? firstShow.start_utc_timestamp : 0;
        let largestShowTimestamp = firstShow.end_utc_timestamp ? firstShow.end_utc_timestamp : 0;
        let startTimeSet = false;
        for (let i = 1; i < shows.length; i++) {
            let currentShow = shows[i];
            const currentTime = moment.utc();
            const startTimestamp = convertIntoMoment(currentShow.start_utc_timestamp || 0);
            if (!currentShow.is_hidden &&
                !currentShow.sold_out &&
                currentShow.start_utc_timestamp &&
                currentShow.end_utc_timestamp) {
                if (startTimestamp.isAfter(currentTime)) {
                    if (!startTimeSet && currentShow.start_utc_timestamp < smallestShowTimestamp) {
                        smallestShowTimestamp = currentShow.start_utc_timestamp;
                        startTimeSet = true;
                    }
                }
                if (currentShow.end_utc_timestamp > largestShowTimestamp) {
                    largestShowTimestamp = currentShow.end_utc_timestamp;
                }
            }
        }
        dateString = generateDateString(smallestShowTimestamp, largestShowTimestamp);
        if (smallestShowTimestamp) {
            subString = isOnline ? 'Join by ' : 'Gates open at ';
            const startsBefore = convertIntoMoment(smallestShowTimestamp).subtract(isOnline ? 15 : 30, 'minutes');
            const timeFormatter = startsBefore.minutes() === 0 ? "h A" : "h:mm A";
            subString += startsBefore.format(timeFormatter);
        }
        dateString = dateString + " onwards";
    }
    return {
        dateString,
        subString
    };
};
export const getPriceString = (venue, isFree, defaultString) => {
    let allItemPrices = [];
    const shows = venue.shows || [];
    // getting all item prices
    shows.forEach(show => {
        const isSoldOut = show.sold_out ? show.sold_out : false;
        const isHidden = show.is_hidden ? show.is_hidden : false;
        if (!isSoldOut && !isHidden && show.items_for_sale) {
            show.items_for_sale.forEach(itemGroup => {
                const isGroupAvailable = itemGroup.is_available ? itemGroup.is_available : false;
                if (isGroupAvailable && itemGroup.items) {
                    itemGroup.items.forEach(item => {
                        const availability_date = item.availability_date;
                        const itemAvailableForPurchse = item.quantity_available_for_purchase || 0 > 0;
                        let isAfter = false;
                        if (availability_date) {
                            const itemAvailableDate = new Date(availability_date);
                            const curr = new Date();
                            isAfter = itemAvailableDate > curr;
                        }
                        if (!item.is_hidden && item.item_state === "available" && isAfter && itemAvailableForPurchse && !allItemPrices.includes(item.price)) {
                            allItemPrices.push(item.price);
                        }
                    });
                }
            });
        }
    });
    // sorting in ascending order
    allItemPrices.sort((a, b) => a - b);
    // getting first item price
    let firstItemPrice = allItemPrices[0];
    let useFree = firstItemPrice === 0 && isFree;
    if (allItemPrices.length > 0) {
        if (allItemPrices.length === 1) {
            if (useFree) {
                return "Free";
            }
            return (firstItemPrice || "").toLocaleString("en-IN");
        }
        else {
            return `${(firstItemPrice || "").toLocaleString("en-IN")} onwards`;
        }
    }
    else
        return defaultString;
};
export const getSanitisedHtml = (html) => {
    const cleanedHtml = html.replace(/(<br\s*\/?>\s*){2,}/g, '<br/>');
    return cleanedHtml;
};
export const validateURL = (redirectUrl, fallback) => {
    try {
        const url = new URL(redirectUrl, window.location.origin);
        if (url.origin === window.location.origin) {
            return url.href;
        }
        else {
            return fallback;
        }
    }
    catch (e) {
        // If redirectUrl is invalid, fall back to a safe URL
        return fallback;
    }
};
export const redirectToDistrictPath = (eventData, buyPath, cb) => {
    if (!eventData)
        return;
    const blacklistTagId = "67a32df378ca6bc883c8238f";
    const eventDataJS = eventData.toJS();
    const path = window && window.location && window.location.pathname;
    const checkForTags = eventData
        .get("tags", [])
        .map(t => {
        const tagId = t.get("tag_id");
        return typeof tagId === "object" ? tagId.get("_id") : tagId;
    })
        .includes(blacklistTagId);
    if (eventDataJS.event_type === "physical" && path && !checkForTags) {
        const redirectionUrl = "https://www.district.in" + (buyPath ? buyPath : path);
        if (isFromApp() || isH5Build) {
            openInBrowserForApp(redirectionUrl);
        }
        else {
            window.location.replace(redirectionUrl);
        }
    }
    else if (buyPath) {
        window.location.href = buyPath;
    }
    else if (cb) {
        cb();
    }
};
export const redirectToDistrictHomepage = () => {
    const redirectionUrl = "https://www.district.in";
    if (isFromApp() || isH5Build) {
        openInBrowserForApp(redirectionUrl);
    }
    else {
        window.location.replace(redirectionUrl);
    }
};
