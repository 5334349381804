let DOMPurify;
if (typeof window !== 'undefined') {
    // Only import dompurify on the client side
    DOMPurify = require('dompurify')(window);
}
export const sanitizeHtml = (html) => {
    if (DOMPurify) {
        return DOMPurify.sanitize ? DOMPurify.sanitize(html) : html;
    }
    return html;
};
