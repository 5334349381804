/** @jsx jsx */
import React, { Fragment } from "react";
import { Global, jsx, css } from "@emotion/core";
import AppBarContainer from "../containers/AppBarWithSubscription";
import InsiderLoadable from "./InsiderLoadable";
import theme from "../styles";
import { colors, mediaFeatures } from "../styles/common";
import HomePageCitySelector from "../containers/HomePageCitySelectorWithSubscription";
import cookies from "browser-cookies";
import { checkToShowCitySelectorOverlay } from "../helpers/misc";
import LoudSpeakerIcon from "./ui/icons/LoudSpeakerIcon";
import { Base, BaseMedium, XSmall } from "./ui-lib/Atoms/Typography/styles";
import { InsiderLink } from "./InsiderLink";
import _, { includes, isEmpty } from "lodash";
import { allowNewIframeRedirectMerchantId, allowedIframeOrigins } from "../helpers/constants";
import { isH5Build } from "../helpers/misc";
import { openInBrowserForApp } from "../helpers/appBridge";
const isRequestedByApp = platform => platform === "ios" || platform === "android";
const shouldBeSPA = platform => !isRequestedByApp(platform);
const extractPlatformFromProps = props => {
    let platform = props.location.query.platform;
    if (typeof window !== "undefined" && typeof window.sessionStorage !== "undefined") {
        if (!platform) {
            // if platform doesn't exist try extracting from session storage
            const platformInSession = window.sessionStorage.getItem("platformKey");
            if (platformInSession)
                platform = platformInSession;
        }
        if (platform)
            window.sessionStorage.setItem("platformKey", platform);
    }
    return platform;
};
let showShowMainBanner = false;
let embedCookie;
// Emotion JS
const MainStyles = `
	position: relative;
	background: white;
`;
/* Dynamic Components */
const Toast = InsiderLoadable({
    loader: () => import(/* webpackChunkName: "Toast" */ "./Toast")
});
const ToastMessage = InsiderLoadable({
    loader: () => import(/* webpackChunkName: "ToastMessage" */ "./ToastMessage")
});
const ConfirmModal = InsiderLoadable({
    loader: () => import(/* webpackChunkName: "ConfirmModal" */ "../containers/ConfirmModalWithSubscription")
});
const LoginModal = InsiderLoadable({
    loader: () => import(/* webpackChunkName: "LoginModal" */ "../containers/LoginModalWithSubscription")
});
const ProfileModal = InsiderLoadable({
    loader: () => import(/* webpackChunkName: "ProfileModal" */ "../containers/ProfileModalWithSubscription")
});
const FollowIntroModal = InsiderLoadable({
    loader: () => import(/* webpackChunkName: "FollowIntroModal" */ "../containers/FollowIntroModalWithSubscription")
});
const RemindMeModal = InsiderLoadable({
    loader: () => import(/* webpackChunkName: "RemindMeModal" */ "../containers/RemindMeModalWithSubscription")
});
const VideoModal = InsiderLoadable({
    loader: () => import(/* webpackChunkName: "VideoModal" */ "../containers/VideoModalWithSubscription")
});
const Footer = InsiderLoadable({
    loader: () => import(/* webpackChunkName: "Footer" */ "./Footer")
});
// @todo move to separate component
const GlobalStyles = props => (<Global styles={{
        body: Object.assign({ fontFamily: theme.fontFamily }, (props.isIframeEmbed && { background: "white" }))
    }}/>);
const announcementHeaderDiv = `
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 9px 15px;
	background: linear-gradient(90deg, #C446FF -14.33%, #7801FB 38.59%, #3E0082 98.88%);
	text-align: center;
	${XSmall}
	font-weight: 500;
	color: ${colors["insiderGrey"]};
	text-align: left;
	position: relative;
	z-index: 119;
	font-size: 16px;
	border-bottom: 2px solid #0c172f;
	height: 48px;
	
	p {
		color: #F2EBE3;
		text-align: center;
		font-style: normal;
		font-weight: 500;
		line-height: 133%;
	}
	span {
		font-weight: 700;
		cursor: pointer;
	}

	@media screen and (max-width: 768px){
		font-size: 12px;
	}
	`;
const DistrictImage = `
    width: 70px;
	margin: 0 10px;
	@media screen and (max-width: 768px){
		width: 52.5px;
		margin: 0 7px;
	}
    
`;
class App extends React.PureComponent {
    componentDidMount() {
        const { setPlatform, setSPAMode, setSocialLoginFlag } = this.props;
        const platform = extractPlatformFromProps(this.props);
        embedCookie = cookies.get("embed");
        setSPAMode(shouldBeSPA(platform));
        setPlatform(platform);
        const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator && window.navigator.userAgent) || false;
        if (isMobile) {
            setSocialLoginFlag();
        }
        if (location && location.pathname && !location.pathname.endsWith("/announcement")) {
            showShowMainBanner = true;
        }
    }
    componentWillReceiveProps(nextProps) {
        const { setPlatform, setSPAMode } = this.props;
        const platform = extractPlatformFromProps(this.props);
        const nextPlatform = extractPlatformFromProps(nextProps);
        embedCookie = cookies.get("embed");
        if (platform !== nextPlatform) {
            setSPAMode(shouldBeSPA(platform));
            setPlatform(platform);
        }
    }
    toast() {
        return (<Fragment>
				<Toast notifications={this.props.notifications}/>
				<ToastMessage notifications={this.props.messages}/>
			</Fragment>);
    }
    showModals() {
        return (<div>
				<FollowIntroModal />
				<LoginModal />
				<ProfileModal />
				<ConfirmModal />
				<RemindMeModal />
				<VideoModal />
			</div>);
    }
    onDownloadClick() {
        const { isFromApp } = this.props;
        const districtURL = isFromApp
            ? "https://link.district.in/DSTRKT/insiderapptbdevents"
            : "https://link.district.in/DSTRKT/insidertopbannerdevents";
        if (isH5Build || isFromApp) {
            openInBrowserForApp(districtURL);
        }
        else {
            window.open(districtURL, "_blank");
        }
    }
    getBodyForPlatform(platform) {
        const { children, location, modalType, notifications, messages, cities, selectedCity, isFromApp } = this.props;
        var hideChrome = platform === "ios" || platform === "android";
        const isEmbed = location.query.embed === "true";
        const isSamsungDevice = platform === "samsungwallet";
        const merchantId = location.query.merchantId;
        const origin = location.query.origin;
        const cookieKeys = window && Object.keys(cookies.all());
        const hideCitySelectorOverlay = checkToShowCitySelectorOverlay(isEmbed, merchantId, cookieKeys) ? true : false;
        if (hideCitySelectorOverlay) {
            //avoiding overlay after queue as well
            window && cookies.set("skip_online_overlay", "true", { secure: true, expires: 7 });
        }
        let isIframeEmbed = isEmbed;
        if (!_.isUndefined(embedCookie)) {
            isIframeEmbed = isEmbed || embedCookie;
        }
        if (isEmbed &&
            !embedCookie &&
            allowNewIframeRedirectMerchantId.includes(merchantId) &&
            allowedIframeOrigins.includes(origin)) {
            if (typeof document !== "undefined" && typeof navigator !== "undefined") {
                document.cookie = "embed=true; SameSite=None; Secure; path=/;";
            }
        }
        if (hideChrome) {
            return (<div css={css(MainStyles)}>
					<GlobalStyles isIframeEmbed={isIframeEmbed}/>
					{children}
					{this.toast()}
				</div>);
        }
        return (<div css={css(MainStyles)}>
				<GlobalStyles isIframeEmbed={isIframeEmbed}/>
				{!hideCitySelectorOverlay && !isIframeEmbed && <HomePageCitySelector />}
				{!isIframeEmbed && <AppBarContainer {...this.props}/>}
				{showShowMainBanner && !isIframeEmbed && (<div css={css(announcementHeaderDiv)} onClick={() => this.onDownloadClick()}>
						<p>insider.in has a new home.</p>
						<img css={css(DistrictImage)} src="https://media.insider.in/image/upload/c_crop,g_custom/v1733238031/p9y0yent7xhdxc7fhxuq.png"/>
						<span>
							Download the app
						</span>
					</div>)}
				{children}
				{(notifications.size > 0 || messages.size > 0) && this.toast()}
				{Boolean(modalType) && this.showModals()}
				{!isIframeEmbed && !isSamsungDevice && (<Footer selectedCity={selectedCity} pinnedCities={cities
                    .get(true)
                    .filter(city => city.get("slug") !== "homescreen-online")
                    .toJS()}/>)}
			</div>);
    }
    render() {
        return this.getBodyForPlatform(extractPlatformFromProps(this.props));
    }
}
export default App;
